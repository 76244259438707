import React, {Component} from 'react';

import Select from 'react-select';

import PropTypes from 'prop-types';

import nools from "nools";

import {Form, Button, Modal, Container, Row, Col, Card, CardColumns} from 'react-bootstrap';

import _ from 'lodash';

import evaluate from "simple-evaluate";
import * as util from 'util'

import work_rules from './out.js'

import Parser from 'react-html-parser';
import {convertNodeToElement} from 'react-html-parser';


import Accommodation from './Accommodation';
import {Link} from "react-router-dom";

import {AiFillHeart, AiOutlineHeart} from 'react-icons/ai';

import NavPanel from "./NavPanel";
import {MdHelp, MdInfo} from "react-icons/md";


const m_options = [
    {value: 'M1', label: 'Has difficulty sitting for 30+ minutes', longText: 'The employee has difficulty sitting for sustained periods (e.g., 30 minutes or longer)'},
    {value: 'M2', label: 'Has difficulty standing for 30+ minutes', longText: 'The employee has difficulty standing for sustained periods (e.g., 30 minutes of longer)'},
    {value: 'M3', label: 'Has difficulty standing up', longText: 'The employee has difficulty standing up from a seated position'},
    {value: 'M4', label: 'Has difficulty with balance', longText: 'The employee has difficulty balancing'},
    {value: 'M5', label: 'Has difficulty bending', longText: 'The employee has difficulty bending'},
    {value: 'M6', label: 'Has difficulty lifting', longText: 'The employee has difficulty lifting objects'},
    {value: 'M7', label: 'Has difficulty or cannot go up/down steps', longText: 'The employee has difficulty going up or down steps'},
    {value: 'M8', label: 'Has difficulty or cannot walk', longText: 'The employee has difficulty walking'},
];

const u_options = [
    {value: 'U00', label: 'U00-Dom:NoDiff;Non-dom:NoDiff'},
    {value: 'U01', label: 'U01-Dom:NoDiff;Non-dom:RepetitiveMotion'},
    {value: 'U02', label: 'U02-Dom:NoDiff;Non-dom:Reaching/Lifting'},
    {value: 'U03', label: 'U03-Dom:NoDiff;Non-dom:Controlling'},
    {value: 'U04', label: 'U04-Dom:NoDiff;Non-dom:VeryLimited'},

    {value: 'U10', label: 'U10-Dom:RepetitiveMotion;Non-dom:NoDiff'},
    {value: 'U11', label: 'U11-Dom:RepetitiveMotion;Non-dom:RepetitiveMotion'},
    {value: 'U12', label: 'U12-Dom:RepetitiveMotion;Non-dom:Reaching/Lifting'},
    {value: 'U13', label: 'U13-Dom:RepetitiveMotion;Non-dom:Controlling'},
    {value: 'U14', label: 'U14-Dom:RepetitiveMotion;Non-dom:VeryLimited'},

    {value: 'U20', label: 'U20-Dom:Reaching/Lifting;Non-dom:NoDiff'},
    {value: 'U21', label: 'U21-Dom:Reaching/Lifting;Non-dom:RepetitiveMotion'},
    {value: 'U22', label: 'U22-Dom:Reaching/Lifting;Non-dom:Reaching/Lifting'},
    {value: 'U23', label: 'U23-Dom:Reaching/Lifting;Non-dom:Controlling'},
    {value: 'U24', label: 'U24-Dom:Reaching/Lifting;Non-dom:VeryLimited'},

    {value: 'U30', label: 'U30-Dom:Controlling;Non-dom:NoDiff'},
    {value: 'U31', label: 'U31-Dom:Controlling;Non-dom:RepetitiveMotion'},
    {value: 'U32', label: 'U32-Dom:Controlling;Non-dom:Reaching/Lifting'},
    {value: 'U33', label: 'U33-Dom:Controlling;Non-dom:Controlling'},
    {value: 'U34', label: 'U34-Dom:Controlling;Non-dom:VeryLimited'},

    {value: 'U40', label: 'U40-Dom:VeryLimited;Non-dom:NoDiff'},
    {value: 'U41', label: 'U41-Dom:VeryLimited;Non-dom:RepetitiveMotion'},
    {value: 'U42', label: 'U42-Dom:VeryLimited;Non-dom:Reaching/Lifting'},
    {value: 'U43', label: 'U43-Dom:VeryLimited;Non-dom:Controlling'},
    {value: 'U44', label: 'U44-Dom:VeryLimited;Non-dom:VeryLimited'},
];

const h_options = [

    {value: 'H1', label: 'Has noise sensitivity', longText:'The employee can hear clearly, but is sensitive to noise'},
    {value: 'H2', label: 'Has ringing in the ears (tinnitus)', longText:'The employee can hear, but has ringing in the ears (tinnitus)'},
    {value: 'H3', label: 'Has difficulty hearing speech, unless uses hearing aid', longText:'The employee can hear conversational speech clearly, but only when using my personal hearing aid or other assisted listening device.'},
    {value: 'H4', label: 'Has difficulty hearing speech, even with hearing aid', longText:'The employee can hear somewhat, but has difficulty hearing conversational speech clearly'},
    {value: 'H5', label: 'Is deaf or has limited hearing', longText:'The employee is deaf or has very limited usable hearing, even with the use of a hearing aid or other assisted listening device'},
    {value: 'H6', label: 'Is deaf-blind', longText:'The employee is deaf-blind'}

];

const v_options = [

    {value: 'V1', label: 'Has light sensitivity', longText:'The employee can see clearly, but is sensitive to light'},
    {value: 'V2', label: 'Has difficulty telling colors apart', longText:'The employee can see clearly, but has difficulty distinguishing colors (am color-blind)'},
    {value: 'V3', label: 'Has difficulty seeing details', longText:'The employee can see somewhat, but has difficulty seeing details (e.g., newsprint), due to problems with visual acuity or blurred vision'},
    {value: 'V4', label: 'Does not have a full field of vision', longText:'The employee can see somewhat, but has a limited field of vision'},
    {value: 'V5', label: 'Is blind or has limited vision', longText:'The employee is blind or has very limited usable vision'},
    {value: 'V6', label: 'Is deaf-blind', longText:'The employee is deaf-blind'}
];

const s_options = [

    {value: 'S1', label: 'Has difficulty thinking of the right words to say', longText:'The employee speaks clearly, but has significant difficulty thinking of the right words to say'},
    {value: 'S2', label: 'Stutters', longText:'The employee has difficulty with stuttering'},
    {value: 'S3', label: 'Has a weak voice', longText:'The employee’s speech is not usually understood due to a weak voice'},
    {value: 'S4', label: 'Has difficulty with articulation', longText:'The employee’s speech is not usually understood due to difficulties with articulation (including deaf-related)'},
    {value: 'S5', label: 'Is unable to speak', longText:'The employee is unable to speak'}

];

const c_options = [

    {value: 'C1', label: 'Has difficulty sustaining or dividing attention', longText:'The employee has difficulty sustaining, shifting, or dividing attention'},
    {value: 'C2', label: 'Has difficulty remembering short- or long-term', longText:'The employee has significant difficulty with short-term or long-term memory'},
    {value: 'C3', label: 'Has difficulty regulating or expressing emotions', longText:'The employee has difficulty regulating emotions or expressing appropriate emotions'},
    {value: 'C4', label: 'Has difficulty organizing, planning, or managing time', longText:'The employee has difficulty with organization, planning, or time management'},
    {value: 'C5', label: 'Has difficulty performing calculations', longText:'The employee has difficulty with simple and complex calculations'},
    {value: 'C6', label: 'Has difficulty reading or writing', longText:'The employee has difficulty with reading or expressing written language'},
    {value: 'C7', label: 'Has difficulty understanding verbal directions', longText:'The employee has difficulty understanding verbal directions, especially multiple step instructions'}

];


const task_options = [
    {value: 'T0100', label: 'T01.0-FacAcc:Doors'},
    {value: 'T0101', label: 'T01.1-FacAcc:Doors:Locate'},
    {value: 'T0102', label: 'T01.2-FacAcc:Doors:AccessSecurity'},
    {value: 'T0103', label: 'T01.3-FacAcc:Doors:Open/Close'},
    {value: 'T0104', label: 'T01.4-FacAcc:Doors:PassThru'},
    // {value: 'T0105', label: 'T01.5'},
    // {value: 'T0106', label: 'T01.6'},
    // {value: 'T0107', label: 'T01.7'},
    // {value: 'T0108', label: 'T01.8'},
    // {value: 'T0109', label: 'T01.9'},
    // {value: 'T0110', label: 'T01.10'},

    {value: 'T0200', label: 'T02.0-FacAcc:BdgLvls'},
    {value: 'T0201', label: 'T02.1-FacAcc:BdgLvls:Stairs'},
    {value: 'T0202', label: 'T02.2-FacAcc:BdgLvls:Ramp'},
    {value: 'T0203', label: 'T02.3-FacAcc:BdgLvls:w/MobilityAid'},
    {value: 'T0204', label: 'T02.4-FacAcc:BdgLvls:Elevator'},
    // {value: 'T0205', label: 'T02.5'},
    // {value: 'T0206', label: 'T02.6'},
    // {value: 'T0207', label: 'T02.7'},
    // {value: 'T0208', label: 'T02.8'},
    // {value: 'T0209', label: 'T02.9'},
    // {value: 'T0210', label: 'T02.10'},

    {value: 'T0300', label: 'T03.0-FacAcc:WorkplaceMvt'},
    {value: 'T0301', label: 'T03.1-FacAcc:WorkplaceMvt:MoveThru'},
    {value: 'T0302', label: 'T03.2-FacAcc:WorkplaceMvt:Navigate'},
    // {value: 'T0303', label: 'T03.3'},
    // {value: 'T0304', label: 'T03.4'},
    // {value: 'T0305', label: 'T03.5'},
    // {value: 'T0306', label: 'T03.6'},
    // {value: 'T0307', label: 'T03.7'},
    // {value: 'T0308', label: 'T03.8'},
    // {value: 'T0309', label: 'T03.9'},
    // {value: 'T0310', label: 'T03.10'},

    {value: 'T0400', label: 'T04.0-FacAcc:Restroom'},
    {value: 'T0401', label: 'T04.1-FacAcc:Restroom:MoveAround'},
    {value: 'T0402', label: 'T04.2-FacAcc:Restroom:Toilet'},
    {value: 'T0403', label: 'T04.3-FacAcc:Restroom:Urinal'},
    {value: 'T0404', label: 'T04.4-FacAcc:Restroom:Sink'},
    {value: 'T0405', label: 'T04.5-FacAcc:Restroom:Soap'},
    {value: 'T0406', label: 'T04.6-FacAcc:Restroom:DryHands'},
    // {value: 'T0407', label: 'T04.7'},
    // {value: 'T0408', label: 'T04.8'},
    // {value: 'T0409', label: 'T04.9'},
    // {value: 'T0410', label: 'T04.10'},

    {value: 'T0500', label: 'T05.0-Workspace'},
    {value: 'T0501', label: 'T05.1-Workspace:MovingAround'},
    {value: 'T0502', label: 'T05.2-Workspace:GetIn/OutChair'},
    {value: 'T0503', label: 'T05.3-Workspace:PostureSeated'},
    {value: 'T0504', label: 'T05.4-Workspace:PostureStanding'},
    {value: 'T0505', label: 'T05.5-Workspace:AccessWorkSurface'},
    {value: 'T0506', label: 'T05.6-Workspace:AccessWorkSurf'},
    {value: 'T0507', label: 'T05.7-Workspace:LocMaterials'},
    // {value: 'T0508', label: 'T05.8'},
    // {value: 'T0509', label: 'T05.9'},
    // {value: 'T0510', label: 'T05.10'},

    {value: 'T0600', label: 'T06.0-Comm'},
    {value: 'T0601', label: 'T06.1-Comm:ExpressThoughts'},
    {value: 'T0602', label: 'T06.2-Comm:Listen'},
    {value: 'T0603', label: 'T06.3-Comm:Listen2'},
    // {value: 'T0603', label: 'T06.3'},
    // {value: 'T0604', label: 'T06.4'},
    // {value: 'T0605', label: 'T06.5'},
    // {value: 'T0606', label: 'T06.6'},
    // {value: 'T0607', label: 'T06.7'},
    // {value: 'T0608', label: 'T06.8'},
    // {value: 'T0609', label: 'T06.9'},
    // {value: 'T0610', label: 'T06.10'},

    {value: 'T0700', label: 'T07.0-Transp.Materials'},
    {value: 'T0701', label: 'T07.1-Transp.Materials:CarrySmall'},
    {value: 'T0702', label: 'T07.2-Transp.Materials:CarryLarge'},
    // {value: 'T0703', label: 'T07.3'},
    // {value: 'T0704', label: 'T07.4'},
    // {value: 'T0705', label: 'T07.5'},
    // {value: 'T0706', label: 'T07.6'},
    // {value: 'T0707', label: 'T07.7'},
    // {value: 'T0708', label: 'T07.8'},
    // {value: 'T0709', label: 'T07.9'},
    // {value: 'T0710', label: 'T07.10'},

    {value: 'T0800', label: 'T08.0-AccessMedia'},
    {value: 'T0801', label: 'T08.1-AccessMedia:Read'},
    {value: 'T0802', label: 'T08.2-AccessMedia:WriteByHand'},
    {value: 'T0803', label: 'T08.3-AccessMedia:ComposeText'},
    {value: 'T0804', label: 'T08.4-AccessMedia:Listen/View'},
    {value: 'T0805', label: 'T08.5-AccessMedia:ViewPrez'},
    // {value: 'T0806', label: 'T08.6'},
    // {value: 'T0807', label: 'T08.7'},
    // {value: 'T0808', label: 'T08.8'},
    // {value: 'T0809', label: 'T08.9'},
    // {value: 'T0810', label: 'T08.10'},

    {value: 'T0900', label: 'T09.0-Phone'},
    {value: 'T0901', label: 'T09.1-Phone:RecogRing'},
    {value: 'T0902', label: 'T09.2-Phone:LiftHoldRcvr'},
    {value: 'T0903', label: 'T09.3-Phone:Dial/XferCall'},
    {value: 'T0904', label: 'T09.4-Phone:Listen'},
    {value: 'T0905', label: 'T09.5-Phone:Speak'},
    {value: 'T0906', label: 'T09.6-Phone:SeeDisplay'},
    {value: 'T0907', label: 'T09.7-Phone:VoiceMail'},
    {value: 'T0908', label: 'T09.8-Phone:Simul.UseWithPC/Writing'},
    // {value: 'T0909', label: 'T09.9'},
    // {value: 'T0910', label: 'T09.10'},

    {value: 'T1000', label: 'T10.1-Computer'},
    {value: 'T1001', label: 'T10.1-Computer:Controls'},
    {value: 'T1002', label: 'T10.2-Computer:Keyboard'},
    {value: 'T1003', label: 'T10.3-Computer:Mouse'},
    {value: 'T1004', label: 'T10.4-Computer:ReadMonitor'},
    {value: 'T1005', label: 'T10.5-Computer:Periph(prtr,scnnr)'},
    {value: 'T1006', label: 'T10.6-Computer:StorageMedia'},
    {value: 'T1007', label: 'T10.7-Computer:PCAlerts'},
    {value: 'T1008', label: 'T10.8-Computer:SoftwareMenus'},
    // {value: 'T1009', label: 'T10.9'},
    // {value: 'T1010', label: 'T10.10'},
];


const task_major_options = [
    {value: 'T01', label: 'T01'},
    {value: 'T02', label: 'T02'},
    {value: 'T03', label: 'T03'},
    {value: 'T04', label: 'T04'},
    {value: 'T05', label: 'T05'},
    {value: 'T06', label: 'T06'},
    {value: 'T07', label: 'T07'},
    {value: 'T08', label: 'T08'},
    {value: 'T09', label: 'T09'},
    {value: 'T10', label: 'T10'},
];

const task_minor_options = [
    {value: '1', label: '1'},
    {value: '2', label: '2'},
    {value: '3', label: '3'},
    {value: '4', label: '4'},
    {value: '5', label: '5'},
    {value: '6', label: '6'},
    {value: '7', label: '7'},
    {value: '8', label: '8'},
    {value: '9', label: '9'},
    {value: '10', label: '10'},
];


const NoolsDemoPropTypes = {

    //something: PropTypes.bool.isRequired,

    selectedDifficulties: PropTypes.array.isRequired,
    selectedTasks: PropTypes.array.isRequired,
    selectedTechPrefs: PropTypes.array.isRequired,
    selectedInteractionPrefs: PropTypes.array.isRequired,
    selectedWorkAreaPrefs: PropTypes.array.isRequired,
    session: PropTypes.object.isRequired,
    basePath: PropTypes.string.isRequired,
    startPath: PropTypes.string.isRequired,
    //currLevel: PropTypes.

    favorites: PropTypes.array.isRequired,
    storeFavorite: PropTypes.func.isRequired,

    postMountCallback: PropTypes.func,
}


class NoolsDemo extends Component {


    updateRulesEngine() {

        if(!_.isNil(this.facts))
            this.session.retract(this.facts);

        let accommodation = new Accommodation();

        let runOptions = (options) => {
            if (!_.isNil(options)) {
                for (const o of options) {
                    console.log("NOOLS: SETTING: " + o);
                    accommodation.set(o, true);
                }
            }
        }

        // runOptions(this.state.selectedMOption);
        // runOptions(this.state.selectedCOption);
        // runOptions(this.state.selectedVOption);
        // runOptions(this.state.selectedHOption);
        // runOptions(this.state.selectedUOption);
        // runOptions(this.state.selectedSOption);
        //
        // runOptions(this.state.selectedTOption);

        console.log("incoming diffs");
        console.log(typeof this.props.selectedDifficulties);
        console.log(this.props.selectedDifficulties);

        let sdiff = this.props.selectedDifficulties;

        // let udOpts = sdiff.filter(d => d.includes("UD"));
        // let unOpts = sdiff.filter(d => d.includes("UN"));
        // let noUOpts = sdiff.filter(d => !d.includes("UD") && !d.includes("UN"));
        //
        // let udNumStrs = udOpts.map(s => s.substring(2, s.length));
        // let unNumStrs = unOpts.map(s => s.substring(2, s.length));
        //
        // if(udNumStrs.length === 0)
        //     udNumStrs = udNumStrs.concat("0");
        //
        // if(unNumStrs.length === 0)
        //     unNumStrs = unNumStrs.concat("0");
        //
        // console.log(udNumStrs);
        // console.log(unNumStrs);
        //
        // let uPairings = udNumStrs.flatMap(d => unNumStrs.map(v => "U" + d + v));
        //
        // console.log("upairings");
        // console.log(uPairings);

        //let repairedDiffs = noUOpts.concat(uPairings);

        let repairedDiffs = sdiff;

        console.log("incoming tasks");
        console.log(this.props.selectedTasks);

        console.log("incoming tech prefs");
        console.log(this.props.selectedTechPrefs);

        let tp = this.props.selectedTechPrefs;

        console.log("incoming interaction prefs");
        console.log(this.props.selectedInteractionPrefs);

        let ip = this.props.selectedInteractionPrefs;

        console.log("incoming work area prefs");
        console.log(this.props.selectedWorkAreaPrefs);

        let wap = this.props.selectedWorkAreaPrefs;


        // let repairedTechPrefs = [];
        //
        // if(!tp.includes("nCMP"))
        //     repairedTechPrefs.push("CMP");
        // if(!tp.includes("nPHE"))
        //     repairedTechPrefs.push("PHE");
        // if(!tp.includes("PRT"))
        //     repairedTechPrefs.push("nPRT");

        // if(tp.includes("CMP"))
        //     repairedTechPrefs.push("CMP");
        // if(tp.includes("LOW"))
        //     repairedTechPrefs.push("LOW");
        // if(tp.includes("MOB"))
        //     repairedTechPrefs.push("MOB");

        // console.log("repaired tech prefs");
        // console.log(repairedTechPrefs);

        //runOptions(this.props.selectedDifficulties);
        runOptions(repairedDiffs);
        runOptions(this.props.selectedTasks);
        //runOptions(repairedTechPrefs);
        runOptions(tp);
        runOptions(ip);
        runOptions(wap);

        // let toptions = _.isNil(this.state.selectedTOption) ? [] : this.state.selectedTOption.map( (t) => t.value);
        //
        // console.log("T options unpacked");
        // console.log(toptions);
        //
        // accommodation.set('t', toptions);

        console.log("New settings for nools");
        console.log(accommodation);


        this.facts = accommodation;

        this.session.assert(accommodation);

        // console.log("Facts");
        // console.log(this.state.session.getFacts());
        //

        let fired = [];

        this.session
            .on("fire", (name) => {
                // console.log("pushing");
                // console.log(name);
                fired.push(name);
                // console.log("push list so far: ");
                // console.log(fired);
            });


        let find_matches = () =>
            this.session
                .match()
                .then(() => {
                    // console.log("Rules match is finished! Going to set state now!");
                    // console.log(fired);
                    this.setState({results: fired}, () => {
                    });
                });

        // this.state.session.match()
        //     .then(function(){
        //         console.log("THEN");
        //         //console.log(fired);
        //     });

        this.setState({results: []}, find_matches);

    }



    state = {
        // session: null,
        // facts: null,
        selectedMOption: null,
        selectedUOption: null,
        selectedHOption: null,
        selectedVOption: null,
        selectedSOption: null,
        selectedCOption: null,
        selectedMajorTOption: task_major_options[0],
        selectedMinorTOption: task_minor_options[0],
        selectedTOption: null,//(task_major_options[0].value + '.' + task_minor_options[0].value),
        showModal: false,
        showExtraModal: false,
        extraModalText: "",
        showStrategy: false,
        selectedStrategy: null,
        selectedSolution: null,
        results: [],

        favoriteDemo: false,
    };

    closeModal() {
        this.setState({showModal: false});
    }

    showModal(strat) {
        this.setState({selectedSolution: strat, showModal: true});
    }

    closeExtraModal() {
        this.setState({showExtraModal: false});
    }

    showExtraModal(mesg) {
        this.setState({extraModalText: mesg, showExtraModal: true});
    }

    closeStrategy() {
        this.setState({showStrategy: false});
    }

    showStrategy(strat) {
        this.setState({selectedStrategy: strat, showStrategy: true});
    }


    handleMChange = selectedMOption => {
        this.setState(
            {selectedMOption},
            () => {
                console.log(`Option selected:`, this.state.selectedMOption);
                this.updateRulesEngine();
            }
        );
    };
    handleUChange = selectedUOption => {
        this.setState(
            {selectedUOption},
            () => {
                console.log(`Option selected:`, this.state.selectedUOption);
                this.updateRulesEngine();
            }
        );
    };
    handleHChange = selectedHOption => {
        this.setState(
            {selectedHOption},
            () => {
                console.log(`Option selected:`, this.state.selectedHOption);
                this.updateRulesEngine();
            }
        );
    };
    handleVChange = selectedVOption => {
        this.setState(
            {selectedVOption},
            () => {
                console.log(`Option selected:`, this.state.selectedVOption);
                this.updateRulesEngine();
            }
        );
    };
    handleSChange = selectedSOption => {
        this.setState(
            {selectedSOption},
            () => {
                console.log(`Option selected:`, this.state.selectedSOption);
                this.updateRulesEngine();
            }
        );
    };
    handleCChange = selectedCOption => {
        this.setState(
            {selectedCOption},
            () => {
                console.log(`Option selected:`, this.state.selectedCOption);
                this.updateRulesEngine();
            }
        );
    };

    handleTChange = selectedTOption => {
        this.setState(
            {
                selectedTOption: selectedTOption,
            },
            () => {
                console.log(`Option selected:`, this.state.selectedTOption);
                this.updateRulesEngine();
            }
        );
    };

    handleMajorTChange = selectedMajorTOption => {
        let selectedTOption = selectedMajorTOption.value + "." + this.state.selectedMinorTOption.value;
        this.setState(
            {
                selectedMajorTOption: selectedMajorTOption,
                selectedTOption: selectedTOption,
            },
            () => {
                console.log(`Option selected:`, this.state.selectedMajorTOption);
                console.log("Task: ", this.state.selectedTOption);
                this.updateRulesEngine();
            }
        );
    };
    handleMinorTChange = selectedMinorTOption => {
        let selectedTOption = this.state.selectedMajorTOption.value + "." + selectedMinorTOption.value;
        this.setState(
            {
                selectedMinorTOption: selectedMinorTOption,
                selectedTOption: selectedTOption,
            },
            () => {
                console.log(`Option selected:`, this.state.selectedMinorTOption);
                console.log("Task: ", this.state.selectedTOption);
                this.updateRulesEngine();
            }
        );
    };


    componentDidMount = async () => {
        console.log("componentDidMount");

        this.updateRulesEngine();

        if(this.props.postMountCallback)
            await this.props.postMountCallback();
    }


    constructor(props) {
        super(props);

        //this.session = null;
        this.session = this.props.session;
        this.facts = null;

        console.log("Nools props");
        console.log(this.props)
    }



    joinOptions(selectedMOption, selectedUOption, selectedHOption,
                selectedVOption, selectedSOption, selectedCOption,
                selectedTOption, selectedMajorTOption, selectedMinorTOption)
    {
        let combo = [];


        if(selectedMOption)
            combo = combo.concat(selectedMOption);
        if(selectedUOption)
            combo = combo.concat(selectedUOption);
        if(selectedHOption)
            combo = combo.concat(selectedHOption);
        if(selectedVOption)
            combo = combo.concat(selectedVOption);
        if(selectedSOption)
            combo = combo.concat(selectedSOption);
        if(selectedCOption)
            combo = combo.concat(selectedCOption);
        if(selectedTOption)
            combo = combo.concat(selectedTOption);
        if(selectedMajorTOption)
            combo = combo.concat(selectedMajorTOption);
        if(selectedMinorTOption)
            combo = combo.concat(selectedMinorTOption);


        return combo;
    }


    render() {


        let props = this.props;

        // console.log(props);

        const {
            selectedMOption, selectedUOption, selectedHOption,
            selectedVOption, selectedSOption, selectedCOption,
            selectedTOption, selectedMajorTOption, selectedMinorTOption, results
        } = this.state;

        let matches = results.map(i => work_rules[i]);

        // console.log("Render: results are: ");
        // console.log(results);


        let labelColSize = 1;
        let dropColSize = 3;

        // console.log("selectedMOption");
        // console.log(selectedMOption);
        // console.log(selectedMOption ? selectedMOption[0] : null);

        //let ism1 = selectedMOption && selectedMOption[0].value === 'M1';


        //TODO would need this if live filtering enabled again
        // let allOptions =  this.joinOptions(selectedMOption, selectedUOption, selectedHOption,
        //     selectedVOption, selectedSOption, selectedCOption,
        //     selectedTOption, selectedMajorTOption, selectedMinorTOption);


        let allOptions = [];
        allOptions = allOptions.concat(this.props.selectedDifficulties);
        allOptions = allOptions.concat(this.props.selectedTasks);


        let unsetOptions = [
            'M1', 'M2', 'M3', 'M4', 'M5', 'M6', 'M7', 'M8',
            'UD1', 'UD2', 'UD3', 'UD4', 'UN1', 'UN2', 'UN3', 'UN4',
            'V1', 'V2', 'V3', 'V4', 'V5', 'V6',
            'H1', 'H2', 'H3', 'H4', 'H5', 'H6',
            'S1', 'S2', 'S3', 'S4', 'S5',
            'C1', 'C2', 'C3', 'C4', 'C5', 'C6', 'C7',
            'T0100', 'T0101', 'T0102', 'T0103', 'T0104', 'T0105',
            'T0600', 'T0601', 'T0602', 'T0603', 'T0604', 'T0605',
            'T3101', 'T3102', 'T3103', 'T3104', 'T3202', 'T3203', 'T3301',
            'T0900', 'T0901',
        ];

        let matchContext = unsetOptions.reduce(
            (matchList, opt, i, arr) => {
                if (allOptions.includes(opt))
                    matchList[opt] = true;
                else
                    matchList[opt] = false;
                return matchList;
            }, {});

        console.log("MATCH CONTEXT");

        console.log(matchContext);

        //console.log("XFORM");

        let xform = (domNode, index) => {

            // console.log("xform parser");
            //
            // console.log("ALL OPTIONS");
            // console.log(allOptions);

            //if (domNode.attribs && domNode.attribs.id === 'replace') {
            if (domNode.type === 'tag' && domNode.name === 'wafactors') {
                if (domNode.attribs) {
                    let doesMatch = false; //make true for AND behavior

                    let matchRuleOverride = false;
                    let matchRuleOutcome = false;

                    Object.keys(domNode.attribs).forEach((attrib) => {

                        let waFactor = attrib.replace(",", "").toUpperCase();

                        console.log("^^^^^^^^^^^^^^^^");
                        console.log("checking wafactor: " + waFactor);

                        if (attrib === "match") {
                            //let s = util.inspect(domNode);
                            //console.log(s);

                            let toEval = domNode.attribs[attrib];

                            console.log(toEval);

                            matchRuleOutcome = evaluate(matchContext, toEval);

                            console.log("matchRuleOutput: " + matchRuleOutcome);

                            matchRuleOverride = true;


                        } else if (!waFactor.includes("NOT_")) {

                            let foundAttribMatches = allOptions.find((elm) => {
                                let selOption = elm.toUpperCase(); //TODO may need value back elm.value.toUpperCase();

                                //console.log("checking wafactor: " + waFactor + " against: " + selOption);

                                return selOption === waFactor;
                            });

                            if (!foundAttribMatches) {
                                doesMatch = true; //make false for AND behavior
                            }

                        } else {

                            waFactor = waFactor.replace("NOT_", "");

                            let negAttribMatches = allOptions.find((elm) => {
                                let selOption = elm.toUpperCase(); //TODO may need value back elm.value.toUpperCase();

                                //console.log("checking wafactor: " + waFactor + " against: " + selOption);

                                return selOption === waFactor;
                            });

                            // OR behavior
                            if (!negAttribMatches) {
                                doesMatch = true;
                            }

                            // for AND Behavior
                            // if( negAttribMatches) {
                            //     doesMatch = false;
                            // }

                        }


                    });

                    if ((matchRuleOverride && matchRuleOutcome) || (!matchRuleOverride && doesMatch)) {
                        //console.log(matchRuleOverride + " " + matchRuleOutcome + " " + doesMatch);

                        domNode.name = 'div';
                        domNode.children.map(x => console.log(x.data));
                        return (

                            convertNodeToElement(domNode, index, xform)
                            //<div>M1 CONTENTS: {domNode.children ? domNode.children.map(x => <div>{x.data}</div>) : <div></div>}</div>
                        );
                    } else {
                        return <div></div>;
                    }
                } else {
                    //ability tag didn't match conditions because no attribs, and attribs required for matching
                    return <div></div>;
                }

            } else {
                // Don't transform since it's not the ability tag
                return;
            }
        };

        const parserOptions = {
            transform: xform
        };

        matches.sort((a, b) => {


            var taskA = a.task.trim().toLowerCase(), taskB = b.task.trim().toLowerCase()
            if (taskA < taskB) //sort string ascending
                return -1
            if (taskA > taskB)
                return 1

            var gapA = a.gap.trim().toLowerCase(), gapB = b.gap.trim().toLowerCase()
            if (gapA < gapB) //sort string ascending
                return -1
            if (gapA > gapB)
                return 1

            var stratA = a.strategy.trim().toLowerCase(), stratB = b.strategy.trim().toLowerCase()
            if (stratA < stratB) //sort string ascending
                return -1
            if (stratA > stratB)
                return 1

            var solA = a.solution_number, solB = b.solution_number
            if (solA < solB) //sort string ascending
                return -1
            if (solA > solB)
                return 1

            // var solA = a.solution.trim().toLowerCase(), solB = b.solution.trim().toLowerCase()
            // if (solA < solB) //sort string ascending
            //     return -1
            // if (solA > solB)
            //     return 1


            return 0 //default return value (no sorting)
        });


        let matchesRed = matches.reduce((accumTask, t) => {
            const index = accumTask.findIndex(y => y.task.trim() === t.task.trim());
            if (index >= 0) {

                const iGap = accumTask[index].gaps.findIndex(y => y.gap.trim() === t.gap.trim());

                if (iGap >= 0) {
                    const iStrat = accumTask[index].gaps[iGap].strategies.findIndex(y => y.strategy.trim() === t.strategy.trim());

                    if (iStrat >= 0) {
                        accumTask[index].gaps[iGap].strategies[iStrat].solutions = [...accumTask[index].gaps[iGap].strategies[iStrat].solutions,
                            {
                                solution: t.solution.trim(),
                                rule: t,
                                parentStrategy: t.strategy.trim()
                            }
                        ];
                    } else {
                        accumTask[index].gaps[iGap].strategies = [...accumTask[index].gaps[iGap].strategies,
                            {
                                strategy: t.strategy.trim(),
                                solutions: [
                                    {
                                        solution: t.solution.trim(),
                                        rule: t,
                                        parentStrategy: t.strategy.trim()
                                    }
                                ],
                                parentGap: t.gap.trim()
                            }
                        ];
                    }

                } else {

                    accumTask[index].gaps = [...accumTask[index].gaps,
                        {
                            gap: t.gap.trim(),
                            strategies: [
                                {
                                    strategy: t.strategy.trim(),
                                    solutions: [
                                        {
                                            solution: t.solution.trim(),
                                            rule: t,
                                            parentStrategy: t.strategy.trim()
                                        }
                                    ],
                                    parentGap: t.gap.trim()
                                }
                            ],
                            parentTask: t.task.trim()
                        }
                    ];

                }

                //accumTask[index].children.push({t})

            } else {
                accumTask = [...accumTask, {
                    task: t.task.trim(),
                    gaps: [
                        {
                            gap: t.gap.trim(),
                            strategies: [
                                {
                                    strategy: t.strategy.trim(),
                                    solutions: [
                                        {
                                            solution: t.solution.trim(),
                                            rule: t,
                                            parentStrategy: t.strategy.trim()
                                        }
                                    ],
                                    parentGap: t.gap.trim()
                                }
                            ],
                            parentTask: t.task.trim()
                        }
                    ]
                }]
            }
            return accumTask;
        }, []);


        console.log("XXXXXXXX11 REDUCED MATCHES ");
        console.log(matchesRed);


        let task = "";
        let strat = "";
        let sols = null;
        let gap = "unset";


        let backPath = this.props.startPath;

        let showStrat = false;

        let taskIndex = -1;
        let gapIndex = -1;
        let stratIndex = -1;

        if (!_.isNil(this.props.taskIndex) && !_.isNil(this.props.gapIndex) && !_.isNil(this.props.stratIndex)
        )
        {
            taskIndex = _.parseInt(this.props.taskIndex);
            gapIndex = _.parseInt(this.props.gapIndex);
            stratIndex = _.parseInt(this.props.stratIndex);


            console.log("taskIndex: " + taskIndex);
            console.log("gapIndex: " + gapIndex);
            console.log("stratIndex: " + stratIndex);

            // console.log(Number.isInteger(taskIndex));
            // console.log(taskIndex >= 0);
            // console.log(taskIndex < matchesRed.length);

            if (Number.isInteger(taskIndex) && taskIndex >= 0 && taskIndex < matchesRed.length)
            {
                //console.log("task index in range");
                if (Number.isInteger(gapIndex) && gapIndex >= 0 && gapIndex < matchesRed[taskIndex].gaps.length)
                {
                    //console.log("gap index in range");
                    if (Number.isInteger(stratIndex) && stratIndex >= 0 && stratIndex < matchesRed[taskIndex].gaps[gapIndex].strategies.length)
                    {
                        //console.log("strat index in range");

                        task = matchesRed[taskIndex].task;
                        gap = matchesRed[taskIndex].gaps[gapIndex].gap;
                        strat = matchesRed[taskIndex].gaps[gapIndex].strategies[stratIndex].strategy;
                        sols = matchesRed[taskIndex].gaps[gapIndex].strategies[stratIndex].solutions;

                        showStrat = true;

                        backPath = this.props.basePath;
                    }
                }
            }
        }



        // if(!_.isNil(this.state.selectedStrategy))
        // {
        //     strat = this.state.selectedStrategy.strategy;
        //     sols = this.state.selectedStrategy.solutions;
        //     gap = this.state.selectedStrategy.parentGap;
        // }


        let content = null;


        // console.log("FAVORITES");
        // console.log(this.props.favorites);
        // console.log("SELSTRAT:");
        // console.log(this.state.selectedStrategy);
        //
        // let findres = undefined;
        //
        // if(!_.isNil(this.state.selectedStrategy)) {
        //     findres = _.has(this.props.favorites, this.state.selectedStrategy.rule.solution_number);
        //
        //     console.log("findRES: " + findres);
        // }

        let expertImgTag = null;

        if(_.has(this, "state.selectedSolution.rule"))
        {
            //console.log("XYXYXYXYXYXYXYXYXYX");
            //console.log(this.state.selectedSolution);

            let soln = this.state.selectedSolution.rule;
            let expertiseLevel = _.get(soln, "expertise_level", 0);
            //let expertiseLevel = _.isNil(soln.expertise_level) ? 0 : soln.expertise_level;

            if(expertiseLevel > 0)
            {
                let imgSrc = null;

                switch (expertiseLevel)
                {
                    case 1:
                        imgSrc = "/expert-0.png";
                        break;
                    case 2:
                        imgSrc = "/expert-1.png";
                        break;
                    case 3:
                        imgSrc = "/expert-2.png";
                        break;
                }

                if(!_.isNil(imgSrc))
                {
                    let expertiseText = _.get(soln, "expertise_text", "");
                    //let expertiseText = _.isNil(soln.expertise_text) ? "" : soln.expertise_text;

                    expertImgTag =

                    <div style={{cursor: 'pointer'}} onClick={(e) => {
                        e.stopPropagation();
                        e.nativeEvent.stopImmediatePropagation();
                        this.showExtraModal(expertiseText);
                    }}>
                        <img src={imgSrc} alt={expertiseText}></img>
                    </div>
                }

            }

        }

        if(_.isNil(expertImgTag))
        {
            expertImgTag = "";
        }

        let parsedSoln = !_.isNil(this.state.selectedSolution) && !_.isNil(this.state.selectedSolution.rule) ?
            Parser(this.state.selectedSolution.rule.solution_long, parserOptions) : <div></div>;

        parsedSoln = <div id={'rendered-html-soln'}>{parsedSoln}</div>;


        console.log("Favorites!::");
        console.log(this.props.favorites);

       // if(this.state.showStrategy) {
        if(showStrat) {
            content = (

                <div>


                    <Modal class={"modal-dialog smodal-90"} show={this.state.showExtraModal} onHide={() => this.closeExtraModal()}
                           scrollable={true}>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <div class={"gap-modal-title"}>
                                    {
                                        "Expertise Detail"
                                    }
                                </div>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <ul>
                                <div class={"strat-modal-body"}>
                                    {
                                            <div>
                                                {this.state.extraModalText}
                                            </div>
                                    }
                                </div>
                            </ul>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => this.closeExtraModal()}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>





                    <Modal class={"modal-dialog smodal-90"} show={this.state.showModal} onHide={() => this.closeModal()}
                           scrollable={true}>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <div class={"gap-modal-title"}>
                                    {
                                        _.isNil(this.state.selectedSolution) ? "None":
                                            this.state.selectedSolution.solution}
                                </div>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <ul>
                                <div class={"strat-modal-body"}>
                                    {

                                        _.isNil(this.state.selectedSolution) ? "None":
                                            <div>
                                                  <p>{parsedSoln}</p>
                                                <p>{expertImgTag}</p>
                                                {/*<p>DEETS: { JSON.stringify(solRes)}</p>*/}
                                                <a href="#"
                                                    onClick={
                                                        () => {

                                                            let sanitizedRawHTML = "";

                                                            try {
                                                                // Find the rendered DOM node
                                                                const container = document.getElementById("rendered-html-soln");
                                                                if (container) {
                                                                    sanitizedRawHTML = container.innerHTML; // Extract rendered HTML
                                                                } else {
                                                                    console.error("Rendered container not found in DOM.");
                                                                }
                                                            } catch (error) {
                                                                console.error("Error extracting rendered HTML:", error);
                                                                sanitizedRawHTML = "";
                                                            }

                                                            console.log("Sanitized Raw HTML:", sanitizedRawHTML);

                                                            this.setState(
                                                                {favoriteDemo: !this.state.favoriteDemo},
                                                                () => {
                                                                    console.log("favorite storage!");
                                                                    //this.state.selectedSolution.rawHTML = "";
                                                                    this.props.storeFavorite(this.state.selectedSolution, sanitizedRawHTML);
                                                                })
                                                        }
                                                    }
                                                >
                                                    {
                                                        //!this.state.favoriteDemo ?
                                                        //!this.props.favorites.find((v) => this.state.selectedSolution.solution === v.solution) ?

                                                        // !_.has(this.props.favorites, this.state.selectedSolution.rule.solution_number) ?
                                                        //     <div><AiOutlineHeart title="Unselected Favorite Heart"/> Add to favorites</div>
                                                        // :
                                                        //     <div><AiFillHeart title="Selected Favorite Heart"/> Remove from favorites</div>

                                                        !this.props.favorites.some(favorite => favorite.rule.solution_number === this.state.selectedSolution.rule.solution_number) ?
                                                            <div><AiOutlineHeart title="Unselected Favorite Heart" /> Add to favorites</div>
                                                            :
                                                            <div><AiFillHeart title="Selected Favorite Heart" /> Remove from favorites</div>
                                                    }

                                                </a>
                                            </div>
                                    }
                                </div>
                            </ul>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => this.closeModal()}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>


                    <div className={"results-base"}>


                        <h2>{gap}</h2>

                        <h3><span class="strategy">{strat}</span></h3>

                        <hr/>

                        <div class="strat-body">
                            <ul>
                            {
                                _.isNil(sols) ? <div>None</div> :
                                    sols.map(solRes =>
                                        (


                                            <li>
                                                <a href="#" onClick={
                                                    () => {

                                                        return this.showModal(solRes);
                                                    }
                                                }>
                                                    <h4>{solRes.solution}</h4>
                                                </a>
                                            </li>


                                            // <li
                                            //     onClick={
                                            //         () => {
                                            //
                                            //             return this.showModal(solRes);
                                            //         }
                                            //     }
                                            // >
                                            //     <h4>
                                            //         {solRes.solution}
                                            //     </h4>
                                            //     {/*<p>{Parser(solRes.rule.solution_long, parserOptions)}</p>*/}
                                            //     {/*<p>DEETS: { JSON.stringify(solRes)}</p>*/}
                                            // </li>
                                        )
                                    )
                            }
                            </ul>

                            {/*<Button onClick={()=>this.closeModal()}>Return</Button>*/}
                            {/*<div class={"strat-body-back"} onClick={()=>this.closeStrategy()}>&lt; Back</div>*/}
                            {/*<Button onClick={()=>this.closeStrategy()}>Close</Button>*/}
                        </div>


                    </div>
                </div>
            );
        } else {


            content = (

                <div>



                    <div class={"results-base"}>


                        {/*<h2>Solutions</h2>*/}
                        {/*<hr/>*/}

                        <div class={"matches"}>

                            {
                                matchesRed.length == 0 ?
                                    'Unfortunately your selections did not find any matches for solutions.  We are continuing to expand our database, but for now, we recommend you back up and choose some different options.'
                                    :
                                matchesRed.map((res, taskIndex) =>
                                    (
                                        <div class={"task"}>
                                            <h3>Task: {res.task}</h3>

                                            {
                                                res.gaps.map((gapRes, gapIndex) =>
                                                    (
                                                        <div class={"gap"}>
                                                            <h4> {gapRes.gap}:</h4>
                                                            <ul>
                                                                {

                                                                    // gapRes.strategies.map( stratRes =>
                                                                    //     (
                                                                    //         <Link to={{pathname: "/TODO"}} tabindex={-1}><Button onClick={()=> {
                                                                    //
                                                                    //             // TODO some callback that sets up the correct data?
                                                                    //             // or pass as part of path?
                                                                    //             //cbk(cell);
                                                                    //         }} ><b>{stratRes.strategy}</b></Button></Link>
                                                                    //     ))

                                                                    gapRes.strategies.map((stratRes, stratIndex) =>
                                                                        (
                                                                            // <li onClick={
                                                                            //     () => {
                                                                            //
                                                                            //         return this.showStrategy(stratRes);
                                                                            //     }
                                                                            // }>
                                                                            //     <h5>{stratRes.strategy} ></h5>
                                                                            //
                                                                            // </li>

                                                                            <li>
                                                                                {/*<a href="#" onClick={*/}
                                                                                {/*    () => {*/}

                                                                                {/*        return this.showStrategy(stratRes);*/}
                                                                                {/*    }*/}
                                                                                {/*}>*/}
                                                                                {/*    <h5>{stratIndex} {stratRes.strategy}</h5>*/}
                                                                                {/*</a>*/}


                                                                                <Link to={this.props.basePath + "/" +
                                                                                         taskIndex.toString() + "/" + gapIndex.toString() + "/" + stratIndex.toString() }>
                                                                                    <h5> {stratRes.strategy}</h5>
                                                                                </Link>


                                                                            </li>
                                                                        )
                                                                    )
                                                                }
                                                            </ul>

                                                        </div>
                                                    )
                                                )
                                            }
                                        </div>


                                    )
                                )
                            }


                        </div>

                        {/*<h2>Results: {(matches.length)} total</h2>*/}
                        {/*<CardColumns>*/}
                        {/*    {matches.map(res => (*/}
                        {/*        <Card style={{width: '18rem'}} key={res.solution_number}>*/}
                        {/*            <Card.Body>*/}
                        {/*                <Card.Title>{res.solution_number.toString() + ": " + res.gap}</Card.Title>*/}
                        {/*                <Card.Subtitle className="mb-2 text-muted">{res.strategy}</Card.Subtitle>*/}
                        {/*                <Card.Text>*/}
                        {/*                    {Parser(res.solution, parserOptions)}*/}
                        {/*                </Card.Text>*/}
                        {/*            </Card.Body>*/}
                        {/*        </Card>*/}
                        {/*    ))}*/}
                        {/*</CardColumns>*/}

                    </div>
                </div>

            );
        }

        let title = "Step 6: Review Accommodation Approaches";

        if(showStrat)
        {
            title = "Step 7: Review Solutions";//"Solutions for Task: " + task;
        }

        return <NavPanel hideInactiveNavButtons={true} title={title} content={content} progress={-1}
                  prev={backPath} next={null}
        />;



    }


}








// return (
//
//     <div>
//
//
//         <h2>Matches</h2>
//
//         <div style={{textAlign: "left"}}>
//
//             <ul>
//                 {
//                     matchesRed.map(res =>
//                         (
//                             <li>
//                                 {res.task}
//
//                                 <ul>
//
//                                     {
//                                         res.gaps.map(gapRes =>
//                                             (
//                                                 <li>
//                                                     {gapRes.gap}
//                                                     <ul>
//                                                         {
//                                                             gapRes.strategies.map(stratRes =>
//                                                                 (
//                                                                     <li>
//                                                                         {stratRes.strategy}
//                                                                         <ul>
//                                                                             {
//                                                                                 stratRes.solutions.map(solRes =>
//                                                                                     (
//                                                                                         <li>
//                                                                                             {solRes.solution}
//                                                                                         </li>
//                                                                                     )
//                                                                                 )
//                                                                             }
//                                                                         </ul>
//                                                                     </li>
//                                                                 )
//                                                             )
//                                                         }
//                                                     </ul>
//
//                                                 </li>
//                                             )
//                                         )
//                                     }
//                                 </ul>
//
//
//                             </li>
//                         )
//                     )
//                 }
//             </ul>
//
//
//         </div>
//
//         <h2>Results: {(matches.length)} total</h2>
//         <CardColumns>
//             {matches.map(res => (
//                 <Card style={{width: '18rem'}} key={res.solution_number}>
//                     <Card.Body>
//                         <Card.Title>{res.solution_number.toString() + ": " + res.gap}</Card.Title>
//                         <Card.Subtitle className="mb-2 text-muted">{res.strategy}</Card.Subtitle>
//                         <Card.Text>
//                             {Parser(res.solution, parserOptions)}
//                         </Card.Text>
//                     </Card.Body>
//                 </Card>
//             ))}
//         </CardColumns>
//
//     </div>
//
// );







NoolsDemo.propTypes = NoolsDemoPropTypes;

export default NoolsDemo;


