import React, {Component,useState} from 'react';


import PropTypes from 'prop-types';

import { withRouter } from "react-router-dom";

import '../scss/App.scss';




import {Form, Button, Modal, Container, Row, Col, Card, Accordion, AccordionCollapse, AccordionToggle} from 'react-bootstrap';


import NavPanel from './NavPanel';
import {Link} from "react-router-dom";



const HomePropTypes = {

    //something: PropTypes.bool.isRequired,
    //title: PropTypes.string.isRequired,
    //content: PropTypes.element.isRequired
    newAssessmentPath: PropTypes.string.isRequired,
    completeFollowupPath: PropTypes.string.isRequired,
    revisePath: PropTypes.string.isRequired,
    favoritesPath: PropTypes.string.isRequired,
    initNewAssessment: PropTypes.func.isRequired,
}




class Home extends Component {

    constructor(props) {
        super(props);


        this.state = {

        }

    }

    startNewAssessment = async () => {

        await this.props.initNewAssessment();

        // Navigate to the next route
        this.props.history.push(this.props.newAssessmentPath);
    };



    render() {

        let props = this.props;


        console.log(props);

        let AccordionHeaderToggle = (props) => {
            const [expanded, setExpanded] = useState(false);

            return  <Accordion.Toggle {...props} as={Card.Header} onClick={() => {setExpanded(!expanded)}} style={{cursor: 'pointer'}} >
                {expanded ? "+ " : "+ "}
                {props.children}
            </Accordion.Toggle>
        };


        let diffTypes = [
            ['Mobility Aid',['Walking Aid such as a cane or walker', 'Manual Wheelchair', 'Power Wheelchair', 'Scooter']],

            ];


        let cards = diffTypes.map((diffType, index) => {
            return <Card key={`accordion-card-${index}`}>
                <AccordionHeaderToggle  eventKey={index}>{diffType[0]}</AccordionHeaderToggle>
                <Accordion.Collapse eventKey={index} >
                    <Card.Body>
                        <Form>
                            {diffType[1].map(
                                (opt, j) => {
                                    return <div key={`default-checkbox-${index}-${j}`} className='mb-3'>
                                        <Form.Check
                                            type='checkbox'
                                            id='default-checkbox'
                                            label={opt}
                                            />
                                    </div>;
                                }
                            )}
                        </Form>
                    </Card.Body>
                </Accordion.Collapse>
            </Card>
        });


        let frm = <div style={{flex: 1}}>
            {/*<p>This is a demo of Work ACCESS, a new online tool / mobile app to help people make informed workplace accommodation decisions.  It will ask you about the person with the accommodation need, the task, and environmental factors, and will offer suggestions for accommodations.  Currently, this demo is not fully functional, but should illustrate what we are creating.  Please share your input on its features and interface!</p>*/}

            <p>
                Work ACCESS is an online tool to help employees with disabilities and their employers explore options for workplace accommodations.
            </p>
            <p>
                When you start a new assessment, you will be asked to identify the difficulties that an employee is having with specific work tasks because of their disability.  The system asks for details about the work tasks, employee’s abilities, and environment.  This tool then suggests technology, strategies, and environmental modifications that might help.
            </p>
            <p>
                Check out the <Link to='best_practices'>Best Practices</Link> page for tips on how to use the Work ACCESS site.
            </p>
            <p>
                Look under User Profile for past Assessments and a list of your favorite solutions.
                A list of Resources has also been provided to help users implement the ideas.
            </p>

            {/*<Link to={props.newAssessmentPath}><Button variant="home" tabindex={-1}>Start a new Assessment</Button></Link>*/}

            <Button
                variant="home"
                tabindex={-1}
                onClick={this.startNewAssessment}
            >
                Start a new Assessment
            </Button>

        </div>;


        //
        //     <div  >
        //     <Row style={{padding: '10px'}}>
        //
        //         {/*<Col style={{textAlign: 'center'}}>*/}
        //         <Col>
        //             <Link to={props.newAssessmentPath}><Button variant="home" tabindex={-1}>Start a new Assessment</Button></Link>
        //
        //         </Col>
        //         <Col>
        //             <Link to={props.completeFollowupPath}><Button variant="home" tabindex={-1}>Previous Assessments</Button></Link>
        //
        //         </Col>
        //         <Col></Col>
        //     </Row>
        //
        //         <Row style={{padding: '10px'}}>
        //
        //             {/*<Col style={{textAlign: 'center'}}>*/}
        //             <Col>
        //
        //                 <Link to={props.newAssessmentPath}><Button variant="home" tabindex={-1}>Accommodation Resources</Button></Link>
        //             </Col>
        //             <Col>
        //
        //                 <Link to={props.completeFollowupPath}><Button variant="home" tabindex={-1}>Complete a Follow-up</Button></Link>
        //             </Col>
        //             <Col></Col>
        //         </Row>
        //
        //
        //         <Row style={{padding: '10px'}}>
        //
        //             {/*<Col style={{textAlign: 'center'}}>*/}
        //             <Col>
        //
        //                 <Link to={props.favoritesPath}><Button variant="home" tabindex={-1}>Favorites</Button></Link>
        //             </Col>
        //             <Col>
        //
        //
        //             </Col>
        //             <Col></Col>
        //         </Row>
        //
        //
        //         {/*<Row style={{padding: '10px'}}>*/}
        //
        //         {/*    <Col>*/}
        //
        //         {/*        <Link to={props.revisePath}><Button variant="home">Revise Assessment</Button></Link>*/}
        //         {/*    </Col>*/}
        //         {/*    <Col>*/}
        //
        //         {/*        /!*<Link to={props.completeFollowupPath}><Button variant="home">Complete a Follow-up</Button></Link>*!/*/}
        //         {/*    </Col>*/}
        //         {/*    <Col></Col>*/}
        //         {/*</Row>*/}
        //         {/*<Row style={{padding: '10px'}}>*/}
        //         {/*    <Col></Col>*/}
        //         {/*    <Col style={{textAlign: 'center'}}>*/}
        //         {/*        <Link to={props.newAssessmentPath}><Button variant="primary">Review a Previous Assessment</Button></Link>*/}
        //         {/*    </Col>*/}
        //         {/*    <Col></Col>*/}
        //         {/*</Row>*/}
        //
        //
        //     {/*    <Row style={{padding: '10px'}}>*/}
        //     {/*        <Col></Col>*/}
        //     {/*        <Col style={{textAlign: 'center'}}>*/}
        //     {/*            <Link to={props.newAssessmentPath}><Button variant="primary">Explore Accommodation Resources</Button></Link>*/}
        //     {/*        </Col>*/}
        //     {/*        <Col></Col>*/}
        //     {/*    </Row>*/}
        //     {/*<Row style={{padding: '10px'}}>*/}
        //     {/*    <Col></Col>*/}
        //     {/*    <Col style={{textAlign: 'center'}}>*/}
        //     {/*        <Link to={props.completeFollowupPath}><Button variant="primary">Complete your Follow-up Survey</Button></Link>*/}
        //     {/*    </Col>*/}
        //     {/*    <Col></Col>*/}
        //     {/*</Row>*/}
        //     </div>
        // </div>;


        return (

            <NavPanel hideInactiveNavButtons={true} title="Welcome to Work ACCESS!!" content={frm} {...props}/>

        );
    }


}


Home.propTypes = HomePropTypes;

export default withRouter(Home);
