import React, {Component} from 'react';

import {Button, ButtonToolbar, Navbar, Nav, NavDropdown, Card, Row, Col} from 'react-bootstrap';

import BootstrapTable from 'react-bootstrap-table-next';
import PropTypes from "prop-types";
import PreviousSessionsProfile from "./PreviousSessions";



import {useHistory, Link} from 'react-router-dom';

const PreviousSessionsTablePropTypes = {


    sessions: PropTypes.array,
    makeSessionActive: PropTypes.func.isRequired,
    deleteSession: PropTypes.func.isRequired,

}




class PreviousSessionsTable extends Component {


    constructor(props) {
        super(props);


    }



    id_formatter(cell, row, rowIndex, cbk)
    {
        console.log("formatextradata");
        console.log(cbk);

        //return <Button onClick={()=>cbk(cell)}><b>{cell}</b></Button>
        return <div>
            <Link to={{pathname: "/nools"}} tabindex={-1}><Button onClick={()=> {
            console.log("onClick with cell:");
            console.log(cell);
            cbk(cell, "SEL");
        }} ><b>Results</b></Button></Link>

            {/*<Link to={{pathname: "/revise_difficulties"}} tabindex={-1}><Button onClick={()=> {*/}
            {/*    console.log("onClick with cell:");*/}
            {/*    console.log(cell);*/}
            {/*    cbk(cell, "SEL");*/}
            {/*}} ><b>{"Revise"}</b></Button></Link>*/}

            {/*<Link to={{pathname: "/saved_difficulties"}} tabindex={-1}><Button onClick={()=> {*/}
            {/*    console.log("onClick with cell:");*/}
            {/*    console.log(cell);*/}
            {/*    cbk(cell, "DEL");*/}
            {/*}} ><b>{"Delete"}</b></Button></Link>*/}

        </div>
    }
    title_formatter(cell, row, rowIndex, formatExtraData)
    {
        return <div>{cell}</div>
    }

    diff_formatter(cell, row, rowIndex, formatExtraData)
    {
        return <div>{JSON.stringify(cell).replace('[','').replace(']','').replace(/"/g,'').replace(/,/g, ", ")}</div>
    }

    task_formatter(cell, row, rowIndex, formatExtraData)
    {
        return <div>{JSON.stringify(cell).replace('[','').replace(']','').replace(/"/g,'').replace(/,/g, ", ")}</div>
    }

    render() {

        // const products = [ {id:0, name:'apples', price:1}, {id:1, name:'oranges', price:2}, {id:2, name:'door hinges', price:3} ];
        // const columns = [{
        //     dataField: 'id',
        //     text: 'Product ID'
        // }, {
        //     dataField: 'name',
        //     text: 'Product Name'
        // }, {
        //     dataField: 'price',
        //     text: 'Product Price'
        // }];

        const columns = [
            {
                dataField: 'id',
                text: 'Date/Time',
                formatter: this.id_formatter,
                //formatExtraData: (c)=>this.props.makeSessionActive(c, ()=>useHistory().push('/nools') ),
                formatExtraData: (c, method)=> {
                    if(method==="SEL")
                        return this.props.makeSessionActive(c, ()=>console.log("pretend new page loaded") )
                    else if(method==="DEL")
                        return this.props.deleteSession(c)
                },
                headerStyle: (column, colIndex) => {
                    return { width: '230px' };
                }
            },
            {
                dataField: 'title',
                text: 'Date/Time',
                formatter: this.title_formatter,
                sort: true
            },
            {
                dataField: 'difficulties',
                text: 'Difficulties',
                formatter: this.diff_formatter
            },
            {
                dataField: 'tasks',
                text: 'Tasks',
                formatter: this.task_formatter
            },
            ];

        return(

            <BootstrapTable keyField='id' data={ this.props.sessions } columns={ columns }
                            // bodyStyle={ {overflowWrap: 'break-word' } }
            />

        );

    }

}

PreviousSessionsTable.propTypes = PreviousSessionsTablePropTypes;


export default PreviousSessionsTable;
