import React, {Component,useState} from 'react';


import PropTypes from 'prop-types';




import {Form, Button, Modal, Container, Row, Col, Card, Accordion, AccordionCollapse, AccordionToggle} from 'react-bootstrap';

import Parser, {convertNodeToElement} from 'react-html-parser';

import NavPanel from './NavPanel';
import _ from "lodash";

import {AiFillHeart, AiOutlineHeart} from "react-icons/ai";
import evaluate from "simple-evaluate";



const FavoritesPropTypes = {

    postMountCallback: PropTypes.func,
    favorites: PropTypes.array.isRequired,
}




class Favorites extends Component {

    constructor(props) {
        super(props);


        this.state = {
            showModal: false,
            selectedSolution: null,

            showExtraModal: false,
            extraModalText: "",
        }



    }



    closeModal() {
        this.setState({showModal: false});
    }

    showModal(fav) {
        this.setState({selectedSolution: fav, showModal: true});
    }


    componentDidMount() {
        if(this.props.postMountCallback)
            this.props.postMountCallback();
    }



    closeExtraModal() {
        this.setState({showExtraModal: false});
    }

    showExtraModal(mesg) {
        this.setState({extraModalText: mesg, showExtraModal: true});
    }






    render() {



        let props = this.props;

        let faves = this.props.favorites;

        console.log(faves);

        let solnText = !_.isNil(this.state.selectedSolution) ? <p> {this.state.selectedSolution.solution_long}</p> : <div></div>;

        console.log("selectedSolution");
        console.log(this.state.selectedSolution);

        if(_.has(this.state, "selectedSolution.rawHTML")) {
            console.log("rawHTML found!");
            solnText = this.state.selectedSolution.rawHTML;
        }
        else {
            console.log("no rawHTML!!!");
        }

        let expertImgTag = null;

        if(_.has(this, "state.selectedSolution"))
        {
            console.log("XYXYXYXYXYXYXYXYXYX");
            console.log(this.state.selectedSolution);

            let soln = this.state.selectedSolution;
            let expertiseLevel = _.get(soln, "expertise_level", 0);
            //let expertiseLevel = _.isNil(soln.expertise_level) ? 0 : soln.expertise_level;

            console.log("expertiseLevel: ", expertiseLevel);

            if(expertiseLevel > 0) {
                let imgSrc = null;

                switch (expertiseLevel) {
                    case 1:
                        imgSrc = "/expert-0.png";
                        break;
                    case 2:
                        imgSrc = "/expert-1.png";
                        break;
                    case 3:
                        imgSrc = "/expert-2.png";
                        break;
                }

                if (!_.isNil(imgSrc)) {
                    let expertiseText = _.get(soln, "expertise_text", "");
                    //let expertiseText = _.isNil(soln.expertise_text) ? "" : soln.expertise_text;

                    // expertImgTag = <img src={imgSrc} alt={expertiseText}></img>;

                    expertImgTag =

                        <div style={{cursor: 'pointer'}} onClick={(e) => {
                            e.stopPropagation();
                            e.nativeEvent.stopImmediatePropagation();
                            this.showExtraModal(expertiseText);
                        }}>
                            <img src={imgSrc} alt={expertiseText}></img>
                        </div>
                }
            }
        } else {
            console.log("NO this.state.selectedSolution.rule!!!");
            console.log(this.state.selectedSolution);
        }


    if(_.isNil(expertImgTag))
    {
        expertImgTag = "";
    }




    let frm = <div>

             <ul>
                 {

                     Object.keys(faves).map(

                         r => {
                             let f = faves[r];

                             return <li key={_.has( f, "rule.solution_number") ? f.rule.solution_number : "0"}>
                                 <a href="#" onClick={
                                     () => {
                                         this.showModal(f.rule);
                                     }
                                 }> {_.has( f, "rule.solution") ? f.rule.solution : "n/a"}</a>
                                 {/*<p>SOLN: {f.rule.solution}</p>*/}
                                 {/*<p>SOLN_LONG: {f.rule.solution_long}</p>*/}
                                 {/*<p>TASK: {f.rule.task}</p>*/}
                                 {/*<p>GAP: {f.rule.gap}</p>*/}
                                 {/*<p>STRAT: {f.rule.strategy}</p>*/}
                             </li>;

                         }
                     )
                 }


             </ul>
             </div>;


        return (

<div>


    <Modal class={"modal-dialog smodal-90"} show={this.state.showExtraModal} onHide={() => this.closeExtraModal()}
           scrollable={true}>
        <Modal.Header closeButton>
            <Modal.Title>
                <div class={"gap-modal-title"}>
                    {
                        "Expertise Detail"
                    }
                </div>
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <ul>
                <div class={"strat-modal-body"}>
                    {
                        <div>
                            {this.state.extraModalText}
                        </div>
                    }
                </div>
            </ul>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={() => this.closeExtraModal()}>
                Close
            </Button>
        </Modal.Footer>
    </Modal>



            <Modal class={"modal-dialog smodal-90"} show={this.state.showModal} onHide={() => this.closeModal()}
                   scrollable={true}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <div class={"gap-modal-title"}>
                            {
                                _.isNil(this.state.selectedSolution) ? "None":
                                    this.state.selectedSolution.solution}
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ul>
                        <div class={"strat-modal-body"}>
                            {

                                _.isNil(this.state.selectedSolution) ? "None" :
                                    <div dangerouslySetInnerHTML={{__html: solnText}}></div>

                                // <div>
                                //     {solnText}
                                //     {/*<p>{expertImgTag}</p>*/}
                                //     {/*<p>DEETS: { JSON.stringify(solRes)}</p>*/}
                                // </div>
                            }
                            <div>{expertImgTag}</div>
                        </div>
                    </ul>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => this.closeModal()}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>


        <NavPanel hideInactiveNavButtons={true} title="Favorites" content={frm}  {...props}/>

</div>
        );
    }


}


Favorites.propTypes = FavoritesPropTypes;

export default Favorites;
