import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const AltLogout = ({ signOut }) => {
    useEffect(() => {
        console.log("*(*(*(*(*(*((*(* alt_logout has been routed!!! )*&)*)*)*)*)*)*");
        signOut(); // Call the signOut function passed as a prop
    }, [signOut]);

    return (
        <div style={{ textAlign: "center", marginTop: "20%" }}>
            <h1>You have been logged out.</h1>
            <p>
                <Link to="/" style={{ textDecoration: "none", color: "blue" }}>
                    Go back to the homepage
                </Link>
            </p>
        </div>
    );
};

export default AltLogout;